import {Component, Vue} from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import {TableOptions, DEFAULT_TABLE_OPTIONS} from "@/api/request";
import {SearchRequest} from "@/api/shop/request";
import DesignShopListGet, {ListItem} from "@/store/design-shop/search";
import MainShopGet from "@/store/main-shop/get";
import AppNameListGet from "@/store/app-name-list/get";
import Flash, {ErrorAlert} from "@/store/common/flash";
import LineShopSearch from "@/store/shop-line-miniapp/search";
import LineShopGet from "@/store/shop-line-miniapp/get";

@Component({components: {UlContentHeader, UlBreadcrumbs, UIDataTable}})
export default class Index extends Vue {
    // ------------
    // 固定値
    // ------------
    // タイトル
    headingMain = "LINEミニアプリ";
    headingSub = "LINE mini app";
    // パンくず
    breadCrumbs = [
        {text: "LINEミニアプリ", disabled: true},
        {text: "店舗一覧", disabled: true}
    ];
    // テーブルヘッダ（UIDataTableコンポーネントに渡す）
    tableHeaders = [
        {text: "店舗ID", value: "id"},
        {text: "店舗名", value: "name"},
        {text: "", value: "space", sortable: false},
        {
            label: "",
            text: "",
            value: "custom",
            sortable: false
        }
    ];

    menuProps = {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304
    };

    // ------------
    // 変動値
    // ------------

    // ローディングステータス
    isLoading = false;

    // 検索入力オプション
    inputOptions = {
        searchShopId: null as number | null,
        selectShopIds: null as number[] | null
    };

    // テーブル検索オプション（UIDataTableコンポーネントに渡す）
    tableOptions = DEFAULT_TABLE_OPTIONS;

    // ------------

    /**
     * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
     */
    get tableItems() {
        return LineShopSearch.getItems;
    }

    /**
     * 総件数（UIDataTableコンポーネントに渡す）
     */
    get totalCount() {
        return LineShopSearch.getTotalCount;
    }

    get shopItems() {
        return LineShopGet.getItems;
    }

    /**
     * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
     */
    async created() {
        await LineShopSearch.restore(this.inputOptions as SearchRequest);
        const request = LineShopSearch.getSearchRequest;
        // 検索入力オプション
        request.searchShopId &&
        (this.inputOptions.searchShopId = request.searchShopId);
        request.selectShopIds &&
        (this.inputOptions.selectShopIds = request.selectShopIds);

        // 検索テーブルオプション
        request.page && (this.tableOptions = request);
        await this.search();
    }

    /**
     * beforeDestroyライフサイクルフック
     */
    async beforeDestroy() {
        await LineShopSearch.clearResponse();
        await LineShopGet.clearResponse();
    }

    /**
     * ページング変更コールバック
     *
     * @param tableOptions TableOptions
     */
    async tableChangeCallback(tableOptions: TableOptions) {
        // 子コンポーネントへpagingOptionsの変更が通知される
        this.tableOptions = tableOptions;
        await this.search();
    }

    /**
     * 検索ボタンがクリックされた際のコールバック
     */
    async searchClickCallback() {
        await Flash.clear();
        await this.search();
    }

    /**
     * テーブル行の設定ボタンがクリックされた際のコールバック
     *
     * @param item 選択行のAdminItem
     */
    async settingClickCallback(item: { id: number }) {
        await this.$router.push({
            name: "line-miniapp-edit",
            params: {shopId: String(item.id)}
        });
    }

    /**
     * 店舗情報の一覧を取得する処理
     */
    async fetchShopList(): Promise<boolean> {
        if (LineShopGet.isSuccess) {
            return true;
        }

        await LineShopGet.get();
        if (!LineShopGet.isSuccess) {
            await Flash.setErrorNow({
                message: LineShopGet.getMessage,
                showReloadButton: true
            } as ErrorAlert);
        }
        return LineShopGet.isSuccess;
    }

    /**
     * 検索処理
     */
    async search() {
        this.isLoading = true;
        let isSuccess = await this.fetchShopList();
        if (!isSuccess) {
            this.isLoading = false;
            return;
        }

        let request = this.createRequest();
        await LineShopSearch.search(request);
        if (!LineShopSearch.isSuccess) {
            await Flash.setErrorNow({
                message: LineShopSearch.getMessage,
                showReloadButton: true
            } as ErrorAlert);
        }

        this.isLoading = false;
    }

    /**
     * dataから検索用のリクエストを作成する
     */
    private createRequest(): SearchRequest {
        const request = this.tableOptions as SearchRequest;
        if (this.inputOptions.searchShopId) {
            request.searchShopId = this.inputOptions.searchShopId;
        } else {
            delete request.searchShopId;
        }

        if (this.inputOptions.selectShopIds) {
            request.selectShopIds = this.inputOptions.selectShopIds;
        } else {
            delete request.selectShopIds;
        }

        return request;
    }
}
